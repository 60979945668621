import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./header.scss"
import image from '../images/logo.png'

const Header = ({ siteTitle }) => (
    <header>
        <div
            className='nav-container'
        >
            <Link
                to="/"
                style={{
                    color: `white`,
                    textDecoration: `none`,
                }}
            >
                <img src={image} />
                <h1>Tukan</h1>
            </Link>
        </div>
    </header >
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header

// New look
// New year
// Take flight with us
// 54D

// No search engines its tourist traps
// Search real people real places real adventures
